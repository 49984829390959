@import url('https://fonts.googleapis.com/css2?family=Spartan:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap');

:root {
    --text: #e0e0e0;
    --card-text: #a9bd75;
    --background-color: #182c25;
    --grey-background: #666967;
    --menu-background: #0c1713;
    --dark-green-background: #111f1a;
    --card: #2b4d41;
    --light-green: #b3d65a;
    --green-accent: #a8cf45;
    --grey: #a1a3a6;
    --background: var(--background-color) radial-gradient(ellipse at bottom, var(--background-color) 0%, #0c0d13 100%);
    --card-background: var(--background-color) radial-gradient(var(--background-color) 0%, #0c0d13 100%);
    --landing-font: 35px;
    --landing-font-medium: 25px;
    --landing-font-small: 20px;
    --landing-font-xsmall: 15px;
    --landing-font-family: 'Spartan', sans-serif;
    --tabs-font-family: 'Open Sans', sans-serif;
}

#root {
    position: relative;
    min-height: 100vh;
}

#page-container {
    padding-bottom: 6rem;
}

.top {
    z-index: 999999;
    position: relative
}

.moving-background {
    opacity: 0.1;
}

.logo-home {
    height: 13vh;
}

.logo {
    height: 6vh;
    position: absolute;
    top: 20px;
    left: 25px;
}

.virgil-logo {
    width: 45vw;
}

.home-container {
    position: absolute;
    top: 35%;
    height: 65%;
}

.landing {
    color: var(--text);
    position: absolute;
    top: 45%;
    display: flex;
    justify-content: center;
    width: 100%;
}

.header-service {
    display: flex;
    justify-content: center;
    padding: 0 10px;
}

.grid-container {
    justify-content: space-evenly;
    align-items: flex-start;
    position: relative;
    top: 10vh;
}

.solutions-container {
    justify-content: flex-end;
    align-items: flex-start;
    align-content: space-around;
    position: relative;
    top: 10vh;
    z-index: 9999;
    padding: 0 20px;
}

.rotated-text {
    transform: rotate(-90deg);
}

.centered-text {
    text-align: center;
}

.down-arrow {
    color: var(--green-accent);
}

.card {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border: 1px solid var(--text);
    height: 35vh;
}

.services-card {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 0 20px;
    height: 30vw;
}

.card-solution {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 0 20px;
    height: 28vw;
}

.ticker {
    font-size: 1.3rem;
}

.supportedioe-icons {
    height: 12vw;
}

.form-control {
    background-color: var(--grey-background);
    opacity: 0.5;
    padding: 5px;
    border-radius: 4px;
}

a[data-link='true'] {
    border-bottom: 1px solid var(--green-accent);
}

.principles-picture {
    height: 15vw;
}

.principles-first {
    text-align: center;
    font-weight: 400;
    font-size: 1.5rem;
}

.principles-rest {
    font-size: 1rem;
}

.contact-info {
    display: flex;
    justify-content: space-between;
}

footer {
    background-color: var(--menu-background);
    opacity: 0.8;
    width: 100%;
    padding: 10px;
    display: flex;
    justify-content: space-evenly;
    position: absolute;
    bottom: 0;
    height: 3rem;
}

.footer-info {
    display: flex;
    justify-content: flex-end;
}

div.ticker {
    width: 100%;
}

@media screen and (max-device-width: 1150px) {
    .solutions-container {
        top: 5vh;
    }
}

@media screen and (max-width: 1280px), (max-device-width: 1280px) {
    .logo-home {
        height: 10vh;
    }

    .grid-container {
        top: 5vh;
    }
}

/*separated to not affect the mobile*/
@media screen and (max-width: 1280px) and (min-height: 890px), (max-device-width: 1150px) {
       .services-card {
        height: 45vw;
    }
}

@media screen and (max-width: 960px), (max-device-width: 1100px) {
    .moving-background {
        position: absolute;
        top: 0;
    }

    .landing {
        top: 30%;
    }

    .card-solution {
        height: 53vw;
    }

    .supportedioe-icons {
        height: 20vw;
    }

    .principles-picture {
        height: 20vw;
    }
}

@media screen and (max-width: 960px) {
    .principles-picture {
        height: 30vw;
    }
}

@media screen and (max-width: 750px), (max-device-width: 750px) {
    .logo-home {
        height: 7vh;
    }

    .virgil-logo {
        height: 20vw;
    }

    .principles-first {
        font-size: 1.1rem;
    }

    .principles-rest {
        font-size: 0.8rem;
    }
}

@media screen and (max-width: 600px), (max-device-width: 600px) {
    .logo {
        height: 5vh;
    }

    .ticker {
        font-size: 1rem;
    }

    .card-solution {
        height: 60vw;
    }

    .supportedioe-icons {
        height: 30vw;
    }

    .principles-picture {
        height: 50vw;
    }
}

@media screen and (max-width: 550px) {
    footer {
        display: inline-grid;
        height: 8rem;
    }

    #page-container {
        padding-bottom: 8rem;
    }
}

@media screen and (max-width: 450px), (max-device-width: 450px) {
    .logo {
        height: 4vh;
    }


    .logo-home {
        height: 6vh;
    }

    .card-solution {
        height: 65vw;
    }

    .supportedioe-icons {
        height: 30vw;
    }

    .grid-container {
        top: 5vh;
    }
}

@media screen and (max-width: 400px), (max-device-width: 400px) {
    .card {
        height: 40vh;
    }

    .ticker {
        font-size: 0.7rem;
    }

    .principles-first {
        font-size: 1rem;
    }

    .principles-rest {
        font-size: 0.7rem;
    }
}

@media screen and (max-width: 360px) {
    .logo {
        height: 3vh;
    }
}

@media screen and (max-device-width: 340px) and (orientation: portrait) {
    .card {
        height: 60vh;
    }
}

@media screen and (max-width: 300px) {
    .card-solution {
        height: 57vw;
    }
}

@media screen and (max-device-width: 960px) and (orientation: landscape) {
    .card {
        height: 60vh;
    }
}

/*Home page statement animation start*/

@keyframes flickerAnimation {
    0% {
        opacity: 1;
    }
    25% {
        opacity: 0.6;
    }
    50% {
        opacity: 0.3;
    }
    75% {
        opacity: 0.5;
    }
    100% {
        opacity: 0.8;
    }
}

@-o-keyframes flickerAnimation {
    0% {
        opacity: 1;
    }
    25% {
        opacity: 0.6;
    }
    50% {
        opacity: 0.3;
    }
    75% {
        opacity: 0.5;
    }
    100% {
        opacity: 0.8;
    }
}

@-moz-keyframes flickerAnimation {
    0% {
        opacity: 1;
    }
    25% {
        opacity: 0.6;
    }
    50% {
        opacity: 0.3;
    }
    75% {
        opacity: 0.5;
    }
    100% {
        opacity: 0.8;
    }
}

@-webkit-keyframes flickerAnimation {
    0% {
        opacity: 1;
    }
    25% {
        opacity: 0.6;
    }
    50% {
        opacity: 0.3;
    }
    75% {
        opacity: 0.5;
    }
    100% {
        opacity: 0.8;
    }
}

.animate-flicker {
    -moz-animation: flickerAnimation 5s backwards;
    -webkit-animation: flickerAnimation 5s backwards;
    -o-animation: flickerAnimation 5s backwards;
    animation: flickerAnimation 5s backwards;
}

/*Home page statement animation end*/

/*Testimonials animation start*/

#drag, #spin {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    margin: auto;
    transform-style: preserve-3d;
    transform: rotateX(-5deg);
}

#drag figure {
    transform-style: preserve-3d;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    line-height: 200px;
    text-align: center;
    box-shadow: 0 0 8px #ccc;
    -webkit-box-reflect: below 10px linear-gradient(transparent, transparent, #0005);
}

#drag figure:hover {
    box-shadow: 0 0 15px #ccca;
    -webkit-box-reflect: below 10px linear-gradient(transparent, transparent, #0007);
}

#ground {
    width: 900px;
    height: 900px;
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translate(-50%, -50%) rotateX(90deg);
}

@keyframes spin {
    from {
        transform: rotateY(0deg);
    }
    to {
        transform: rotateY(360deg);
    }
}

@keyframes spinRevert {
    from {
        transform: rotateY(360deg);
    }
    to {
        transform: rotateY(0deg);
    }
}

/*Testimonials animation end*/